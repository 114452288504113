var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.canVerb(_vm.resource, 'index'))?_c('div',{staticClass:"mt-1"},[_c('b-row',[_c('b-col',{attrs:{"align":"left"}},[_c('b-button-group',{directives:[{name:"b-toggle",rawName:"v-b-toggle:collapse-1",arg:"collapse-1"}],staticClass:"my-2 filter-button-group",attrs:{"title":_vm.getDefaultFilterMessage()}},[_c('span',{staticClass:"when-open"},[_c('b-icon',{attrs:{"icon":"funnel","font-scale":"1.5"}})],1),_vm._v(" "),_c('span',{staticClass:"when-closed"},[_c('b-icon',{attrs:{"icon":"funnel","font-scale":"1.5"}})],1),_vm._v("\n        Filtra\n      ")])],1)],1),_vm._v(" "),_c('b-collapse',{attrs:{"visible":"","id":"collapse-1"}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSearch()}}},[_c('b-card',{staticClass:"filter"},[_c('b-row',[_c('div',{staticClass:"col-md-4"},[_c('base-select',{attrs:{"name":"salesmen","label":"Produttori","options":_vm.opt_salesmen},model:{value:(_vm.filter.byBroker.id),callback:function ($$v) {_vm.$set(_vm.filter.byBroker, "id", $$v)},expression:"filter.byBroker.id"}})],1)]),_vm._v(" "),_c('b-row',[_c('div',{staticClass:"form-group col-md-3 align-self-end"},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle:collapse-1",arg:"collapse-1"}],attrs:{"type":"submit","variant":"lisaweb","size":"sm"}},[_vm._v("Cerca\n            ")]),_vm._v(" "),_c('b-button',{staticClass:"btn-reset",attrs:{"type":"button","variant":"lisaweb","size":"sm"},on:{"click":function($event){return _vm.onClearFilter()}}},[_vm._v("Reset\n            ")])],1)])],1)],1)],1),_vm._v(" "),_c('div',{staticClass:"mt-2"},[_c('div',[_c('b-overlay',{attrs:{"center":"","show":_vm.isLoading,"rounded":"sm"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center mt-5"},[_c('base-icon',{attrs:{"name":"loading","width":"35","height":"35"}}),_vm._v(" "),_c('p',{attrs:{"id":"cancel-label"}},[_vm._v("\n              "+_vm._s(_vm.isLoading
                    ? "Operazione in corso..."
                    : "Elaborazione in corso...")+"\n            ")])],1)]},proxy:true}],null,false,1344808312)},[(!_vm.isLoading)?_c('div',[_c('div',{staticClass:"mt-1"},[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(Object.keys(_vm.model).length),expression:"Object.keys(model).length"}],staticClass:"mb-2",attrs:{"type":"button","pressed":_vm.myToggle,"variant":"light","size":"sm","disabled":_vm.isToggling},on:{"update:pressed":function($event){_vm.myToggle=$event},"click":_vm.onToggleSelectAll}},[_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.isToggling),expression:"isToggling"}],attrs:{"small":"","type":"grow"}}),_vm._v("\n              "+_vm._s(_vm.myToggle ? "Deseleziona" : "Seleziona")+" tutti\n            ")],1)],1),_vm._v(" "),_c('export-options',{attrs:{"exportUrl":_vm.exportUrl,"repository":_vm.repository,"resource":_vm.resource,"tableRef":_vm.$refs[_vm.tableRef],"filter":_vm.filter,"options":[
      {
        code: 'EXPOPRSPRD',
        label: null,
        formats: ['csv', 'pdf'],
      },
    ]}}),_vm._v(" "),_c('sesana',{attrs:{"fields":_vm.fields,"items":_vm.items,"semColor":_vm.toSemColor,"elaColor":_vm.toElaColor},on:{"open_note":_vm.openNoteModal,"check":_vm.onSelectRow,"submit":_vm.onSubmit},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_vm._v(" "),_c('b-row',[_c('p',{staticClass:"title"},[_vm._v("Da Incassare:")]),_vm._v(" "),_c('div',{staticClass:"sem-green"}),_vm._v(" "),_c('p',{staticClass:"label"},[_vm._v("Recuperabile Totalmente")]),_vm._v(" "),_c('div',{staticClass:"sem-yellow"}),_vm._v(" "),_c('p',{staticClass:"label"},[_vm._v("Recuperabile Parzialmente")]),_vm._v(" "),_c('div',{staticClass:"sem-red"}),_vm._v(" "),_c('p',{staticClass:"label"},[_vm._v("Non Recuperabile")])]),_vm._v(" "),_c('b-row',[_c('p',{staticClass:"title"},[_vm._v("Selezione:")]),_vm._v(" "),_c('div',{staticClass:"check-green"}),_vm._v(" "),_c('p',{staticClass:"label"},[_vm._v("Acconto")]),_vm._v(" "),_c('div',{staticClass:"check-red"}),_vm._v(" "),_c('p',{staticClass:"label"},[_vm._v("Sospeso")])]),_vm._v(" "),_c('div',{staticClass:"mt-1"},[_c('b-card',{staticClass:"mt-1",attrs:{"header":"Riepilogo","header-tag":"header"}},[_c('b-card-text',[_c('table',{staticClass:"summary"},[_c('tr',[_c('th',[_vm._v("Sospesi")]),_vm._v(" "),_c('th',[_vm._v("Acconti")]),_vm._v(" "),_c('th',[_vm._v("Saldo Produttore")])]),_vm._v(" "),_c('tr',[_c('td',[_vm._v("\n                      Numero:\n                      "+_vm._s(_vm.getItemsByType("S").length)+"\n                    ")]),_vm._v(" "),_c('td',[_vm._v("\n                      Numero:\n                      "+_vm._s(_vm.getItemsByType("A").length)+"\n                    ")]),_vm._v(" "),_c('td',[_vm._v("\n                      Sospesi-Acconti:\n                      "+_vm._s(_vm.toLocaleCurrency(
                            _vm.getTotalizerByType("S") - _vm.getTotalizerByType("A")
                        ))+"\n                    ")])]),_vm._v(" "),_c('tr',[_c('td',[_vm._v("\n                      Periodo: "+_vm._s(_vm.toLocaleDate(_vm.date("S", "min")))+" -\n                      "+_vm._s(_vm.toLocaleDate(_vm.date("S", "max")))+"\n                    ")]),_vm._v(" "),_c('td',[_vm._v("\n                      Periodo: "+_vm._s(_vm.toLocaleDate(_vm.date("A", "min")))+" -\n                      "+_vm._s(_vm.toLocaleDate(_vm.date("A", "max")))+"\n                    ")]),_vm._v(" "),_c('td')]),_vm._v(" "),_c('tr',[_c('td',[_vm._v("\n                      Totale Aperti:\n                      "+_vm._s(_vm.toLocaleCurrency(_vm.getTotalizerByType("S")))+"\n                    ")]),_vm._v(" "),_c('td',[_vm._v("\n                      Totale Ricevuti:\n                      "+_vm._s(_vm.toLocaleCurrency(_vm.getTotalizerByType("A")))+"\n                    ")]),_vm._v(" "),_c('td')])])])],1),_vm._v(" "),_c('b-button',{staticClass:"mt-2",attrs:{"type":"button","variant":"lisaweb","size":"sm"},on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v("Recupera\n            ")])],1),_vm._v(" "),_c('note-detail-modal',{attrs:{"items":_vm.notes}})],1):_vm._e()])],1)])],1):_c('div',{staticClass:"mt-3"},[_c('b-icon',{attrs:{"icon":"info-circle","scale":"1.00"}}),_vm._v("\n  Non hai i permessi\n")],1)
}
var staticRenderFns = []

export { render, staticRenderFns }