<template>
  <div class="mt-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
            v-b-toggle:collapse-1
            class="my-2 filter-button-group"
            :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch()">
        <b-card class="filter">
          <b-row>
            <div class="col-md-4">
              <base-select
                  name="salesmen"
                  label="Produttori"
                  :options="opt_salesmen"
                  v-model="filter.byBroker.id"
              />
            </div>
          </b-row>
          <b-row>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                  type="submit"
                  variant="lisaweb"
                  size="sm"
                  v-b-toggle:collapse-1
              >Cerca
              </b-button
              >
              <b-button
                  class="btn-reset"
                  type="button"
                  variant="lisaweb"
                  size="sm"
                  @click="onClearFilter()"
              >Reset
              </b-button
              >
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse>
    <div class="mt-2">
      <div>
        <b-overlay center :show="isLoading" rounded="sm">
          <div v-if="!isLoading">
            <div class="mt-1">
              <b-button
                  v-show="Object.keys(model).length"
                  type="button"
                  :pressed.sync="myToggle"
                  variant="light"
                  class="mb-2"
                  size="sm"
                  @click="onToggleSelectAll"
                  :disabled="isToggling"
              >
                <b-spinner small type="grow" v-show="isToggling"></b-spinner>
                {{ myToggle ? "Deseleziona" : "Seleziona" }} tutti
              </b-button
              >
            </div>
            <export-options
                :exportUrl="exportUrl"
                :repository="repository"
                :resource="resource"
                :tableRef="$refs[tableRef]"
                :filter="filter"
                :options="[
        {
          code: 'EXPOPRSPRD',
          label: null,
          formats: ['csv', 'pdf'],
        },
      ]"
            ></export-options>
            <sesana
                :fields="fields"
                :items="items"
                @open_note="openNoteModal"
                @check="onSelectRow"
                @submit="onSubmit"
                v-model="model"
                :semColor="toSemColor"
                :elaColor="toElaColor"
            >
            </sesana>
            <b-row>
              <p class="title">Da Incassare:</p>
              <div class="sem-green"></div>
              <p class="label">Recuperabile Totalmente</p>
              <div class="sem-yellow"></div>
              <p class="label">Recuperabile Parzialmente</p>
              <div class="sem-red"></div>
              <p class="label">Non Recuperabile</p>
            </b-row>
            <b-row>
              <p class="title">Selezione:</p>
              <div class="check-green"></div>
              <p class="label">Acconto</p>
              <div class="check-red"></div>
              <p class="label">Sospeso</p>
            </b-row>
            <div class="mt-1">
              <b-card header="Riepilogo" header-tag="header" class="mt-1">
                <b-card-text>
                  <table class="summary">
                    <tr>
                      <th>Sospesi</th>
                      <th>Acconti</th>
                      <th>Saldo Produttore</th>
                    </tr>
                    <tr>
                      <td>
                        Numero:
                        {{ getItemsByType("S").length }}
                      </td>
                      <td>
                        Numero:
                        {{ getItemsByType("A").length }}
                      </td>
                      <td>
                        Sospesi-Acconti:
                        {{
                          toLocaleCurrency(
                              getTotalizerByType("S") - getTotalizerByType("A")
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Periodo: {{ toLocaleDate(date("S", "min")) }} -
                        {{ toLocaleDate(date("S", "max")) }}
                      </td>
                      <td>
                        Periodo: {{ toLocaleDate(date("A", "min")) }} -
                        {{ toLocaleDate(date("A", "max")) }}
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>
                        Totale Aperti:
                        {{ toLocaleCurrency(getTotalizerByType("S")) }}
                      </td>
                      <td>
                        Totale Ricevuti:
                        {{ toLocaleCurrency(getTotalizerByType("A")) }}
                      </td>
                      <td></td>
                    </tr>
                  </table>
                </b-card-text>
              </b-card>
              <b-button
                  type="button"
                  variant="lisaweb"
                  class="mt-2"
                  size="sm"
                  @click="onSubmit()"
              >Recupera
              </b-button
              >
            </div>
            <note-detail-modal :items="notes"></note-detail-modal>
          </div>
          <template #overlay>
            <div class="text-center mt-5">
              <base-icon name="loading" width="35" height="35"/>
              <p id="cancel-label">
                {{
                  isLoading
                      ? "Operazione in corso..."
                      : "Elaborazione in corso..."
                }}
              </p>
            </div>
          </template>
        </b-overlay>
      </div>
    </div>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import BaseSelect from "@/components/form/BaseSelect";
import ExportOptions from "@/components/ExportOptions";
import NoteDetailModal from "@/components/modals/noteDetailModal";
import {RepositoryFactory} from "@/repositories/RepositoryFactory";
import {prepareFilterQueryString} from "@/utils/forms";
import {toLocaleCurrency} from "@/utils/strings";
import {toLocaleDate /*, fromFirstDay, toLastDay*/} from "@/utils/dates";
import {mergeIndex} from "@/utils/advance-payments";
import {mapGetters} from "vuex";
import common from "@/components/accounting/advance-payment/common";

export default {
  extends: common,
  name: "sesana-produttori",
  data() {
    return {
      exportUrl: null,
      isLoading: false,
      notes: [],
      items: [],
      items1: [],
      items2: [],
      items3: [],
      items4: [],
      selectedNegRows: [],
      selectedPosRows: [],
      negIds: [],
      posIds: [],
      sem: {}, // semaphores for deferreds
      ela: {}, // elaborated accounts
      filter: this.initFilter(),
      tableRef: "BrokersTableRef",
      table: {
        filterOn: {
          byRelations: [
            "byBroker",
            "byRegistry",
            "byInsurer",
            "byInsuranceAncillary",
            "byVariousAccounting",
            "byTask",
          ],
        },
      },
      resource: "book_entries",
      repository: "book_entry",
      opt_salesmen: [],
      fields: [
        {
          key: "type",
          label: "Tipo",
          thClass: "xxxsw",
        },
        {
          key: "book_date",
          label: this.getDictionary("book_date", "book_entry"),
          formatter: (value) => toLocaleDate(value),
          thClass: "sw",
        },
        {
          key: "insurance_policy_number",
          label: this.getDictionary("insurance_policy_number", "book_entry"),
          thClass: "mw",
        },
        {
          key: "registry",
          label: this.getDictionary("registry", "book_entry"),
          thClass: "lw",
        },
        {
          key: "check1",
          type: "checkbox",
          label: this.getDictionary("Sel."),
          thClass: "check zi2",
          tdClass: "check",
        },
        {
          key: "radio",
          type: "radio",
          label: this.getDictionary("Vuoi includere anche i titoli non incassati?"),
          thClass: "check zi2",
          tdClass: "radio-h",
        },
        {
          key: "gross",
          label: this.getDictionary("gross", "book_entry"),
          formatter: (value) => toLocaleCurrency(value),
          thClass: "sw text-right",
          tdClass: "text-right",
        },
        {
          key: "money",
          label: this.getDictionary("Da Incassare"),
          formatter: (value) => toLocaleCurrency(value),
          thClass: "sw text-right",
          tdClass: "text-right",
        },
        {
          key: "note_counter",
          label: this.getDictionary("Note"),
          thClass: "xxxsw text-right",
          tdClass: "float-right",
        },
      ],
      model: {},
      myToggle: false, // #943: select/deselect all checkboxes by button
      isToggling: false,
    };
  },
  components: {
    BaseIcon,
    BaseSelect,
    NoteDetailModal,
    ExportOptions
  },
  methods: {
    toLocaleCurrency,
    toLocaleDate,
    initFilter() {
      let init = {
        byAttribute: {
          title: null,
        },
        byBroker: {
          id: null,
        },
      };
      return init;
    },
    openNoteModal(array) {
      this.notes = array;
      this.$bvModal.show("noteDetailModal");
    },
    onSearch() {
      if (!this.filter.byBroker.id) {
        let missing = {};
        missing.produttore = !this.salesman_id;
        let msg = `Devi selezionare: ${Object.keys(missing)
            .filter((key) => missing[key])
            .join(", ")}`;
        this.$showSnackbar({
          preset: "success",
          text: msg,
          showAction: false,
        });
      } else {
        this.items = [];
        this.isLoading = true;
        this.selectedNegRows = [];
        this.selectedPosRows = [];

        let criteria = {...this.filter};

        let qs = {};
        qs.pagination = "perPage=0";
        if (this.table.filterOn && Object.keys(this.table.filterOn).length) {
          let filterOn = prepareFilterQueryString(this.table.filterOn);
          if (filterOn) {
            qs.filterOn = filterOn;
          }
        }

        // Sospesi
        let criteria1 = {...criteria};
        criteria1.byDeferred = "yes";
        // Acconti
        let criteria2 = {...criteria};
        criteria2.byDeposited = "yes";

        let qs1 = {...qs};
        let qs2 = {...qs};

        let filter1 = prepareFilterQueryString(criteria1);
        if (filter1) {
          qs1.filter = filter1;
        }
        let filter2 = prepareFilterQueryString(criteria2);
        if (filter2) {
          qs2.filter = filter2;
        }

        //   La index sarà il merge di 4 index:
        // - 1. book_entry con un filtro residuo sospeso > 0 (byDeferred)
        // - 2. book_entry con un filtro nuovo: acconto residuo > 0 (by???)

        let queryString1 = Object.values(qs1).join("&");
        let queryString2 = Object.values(qs2).join("&");

        let Repo1 = RepositoryFactory.get(this.repository);
        let Repo2 = RepositoryFactory.get(this.repository);

        const i1 = Repo1.index(queryString1);
        const i2 = Repo2.index(queryString2);

        Promise.all([i1, i2])
            .then((values) => {
              for (const [index, value] of values.entries()) {
                this[`items${index + 1}`] = value.data.data;
              }

              // Check if deposit have registries
              this.items2 = this.items2.filter(
                  (item) => item.registries.length == 0
              );

              // Merge index
              this.items = mergeIndex(this.items1, this.items2);

              // Add check prop to data
              this.items = this.items.map((obj) => ({...obj, check: true}));
              // update myToggle accordingly
              // this.myToggle = true;

              this.negIds = this.items
                  .filter((e) => e.type === "S")
                  .map((e) => e.id);
              this.posIds = this.items
                  .filter((e) => e.type === "A")
                  .map((e) => e.id);

              this.model = {};
              for (const item of this.items) {
                let {
                  id,
                  book_entry_id,
                  book_date,
                  money,
                  type,
                  enabled,
                  check /*, ...rest*/,
                } = item;

                this.$set(this.model, id, {
                  id,
                  book_entry_id,
                  book_date,
                  check,
                  type,
                  money,
                  enabled,
                });

                if (item.type === "S") {
                  this.selectedNegRows = this.pushSortedIds(
                      item.id,
                      this.selectedNegRows,
                      this.negIds
                  );
                }
                if (item.type === "A") {
                  this.selectedPosRows = this.pushSortedIds(
                      item.id,
                      this.selectedPosRows,
                      this.posIds
                  );
                }
              }
              let s = this.semaphore(
                  this.selectedNegRows,
                  this.selectedPosRows,
                  this.model
              );
              let e = this.elaborate(
                  this.selectedNegRows,
                  this.selectedPosRows,
                  this.model
              );
              this.$set(this, "sem", null);
              this.$set(this, "sem", s);
              this.$set(this, "ela", null);
              this.$set(this, "ela", e);
              // update myToggle accordingly (all selected)
              this.myToggle = true;
              this.isLoading = false;
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({preset: "error", text: errMsg});
              this.isLoading = false;
            });
        // .finally(() => {
        //   this.isLoading = false;
        // });
      }
    },
    onClearFilter() {
      this.resetFilter();
      // this.resetPagination();
      // this.removeFilterByName(name);
      // this.removePaginationByName(name);
      // this.removeSortByName(name);
      // this.$refs[this.tableRef].sortBy = "";
      // this.$refs[this.tableRef].sortDesc = false;
      // this.onSearch(name);
    },
    date(type, operation) {
      const dates = this.getItemsByType(type).map((item) => item.book_date);
      if (dates.length) {
        switch (operation) {
          case "min":
            return dates.reduce(function (a, b) {
              return a < b ? a : b;
            });
          case "max":
            return dates.reduce(function (a, b) {
              return a > b ? a : b;
            });
        }
      }
      return null;
    },
    getItemsByType(type) {
      return Object.keys(this.model).length
          ? Object.values(this.model).filter(
              (item) => item.type === type && item.check
          )
          : [];
    },
    getTotalizerByType(type) {
      return this.getItemsByType(type).reduce(function (
              previousValue,
              currentValue
          ) {
            return previousValue + currentValue.money;
          },
          0);
    },
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
    }),
  },
  mounted() {
    this.opt_salesmen = this.getSalesmen();
  },
};
</script>

<style scoped>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.title {
  margin-left: 14px;
  margin-right: 5px;
  padding: 0;
  margin-top: 8px;
  font-size: 12px;
}

.label {
  margin-left: 5px;
  margin-right: 5px;
  padding: 0;
  margin-top: 8px;
  font-size: 12px;
}

.sem-green {
  width: 15px;
  height: 15px;
  background: rgba(0, 255, 0, 0.4);
  margin-left: 18px;
  padding: 0;
  margin-top: 8px;
  font-size: 12px;
}

.sem-yellow {
  width: 15px;
  height: 15px;
  background: rgba(251, 255, 0, 0.4);
  padding: 0;
  margin-top: 8px;
  font-size: 12px;
}

.sem-red {
  width: 15px;
  height: 15px;
  background: rgba(255, 0, 0, 0.4);
  padding: 0;
  margin-top: 8px;
  font-size: 12px;
}

.check-green {
  width: 15px;
  height: 15px;
  background: radial-gradient(#56c456, white);
  margin-left: 18px;
  padding: 0;
  margin-top: 8px;
  font-size: 12px;
}

.check-red {
  width: 15px;
  height: 15px;
  background: radial-gradient(#af545186, white);
  padding: 0;
  margin-top: 8px;
  font-size: 12px;
}
</style>
